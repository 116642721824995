export const GET_PARTICIPANTS = "GET_PARTICIPANTS";
export const FETCH_PARTICIPANTS_FAILED = "FETCH_PARTICIPANTS_FAILED";
export const NEW_PARTICIPANT = "NEW_PARTICIPANT";
export const SELECTED_PARTICIPANT = "SELECTED_PARTICIPANT";
export const CLEAR_PARTICIPANT = "CLEAR_PARTICIPANT";
export const UPDATE_PARTICIPANT_SQUARES = "UPDATE_PARTICIPANT_SQUARES";
export const ADD_FAILED = "ADD_FAILED";
export const UPDATE_FAILED = "UPDATE_FAILED";


// let targetURL = 'http://localhost:8080'
// Group 1 
// let targetURL = 'https://q6yis939mh.execute-api.us-east-2.amazonaws.com/dev/participant';
// Group 2
// let targetURL = 'https://wubcit4utg.execute-api.us-east-2.amazonaws.com/dev/participant';
// let targetURL = 'https://3gg0ifg8ef.execute-api.us-east-2.amazonaws.com';
// let targetURL = 'https://cil-holiday-db.herokuapp.com'
// August 
let targetURL = 'https://kols9n7p50.execute-api.us-east-2.amazonaws.com/prod/participant'

export const getParticipants = () => {
    return dispatch => {
        fetch(`${targetURL}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(participants => dispatch({
                type: GET_PARTICIPANTS,
                payload: participants.Items
            }))
            .catch(err => dispatch({
                type: FETCH_PARTICIPANTS_FAILED,
                payload: err
            }))
    }
}

export const selectedParticipant = (selectedParticipant) => {
    return dispatch => {
        dispatch
            ({
                type: SELECTED_PARTICIPANT,
                payload: selectedParticipant
            })
    }
}

export const clearParticipant = () => {
    return dispatch => {
        dispatch
            ({
                type: CLEAR_PARTICIPANT,
                payload: ""
            })
    }
}

export const updateParticipantSquares = (updatedParticipant) => {
    return dispatch => {
        fetch(`${targetURL}/` + updatedParticipant.leaderboardName,
            {
                method: 'PUT',
                body: JSON.stringify(updatedParticipant),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(response =>
                dispatch
                    ({
                        type: UPDATE_PARTICIPANT_SQUARES,
                        payload: updatedParticipant
                    }))
            .catch(err => dispatch({
                type: UPDATE_FAILED,
                payload: err
            }))
    }
}

// export const newParticipant = (newParticipant) => {
//     return dispatch => {
//         dispatch
//         ({
//             type: NEW_PARTICIPANT,
//             payload: newParticipant

//         })
//     }
// }

export const newParticipant = (newParticipant) => {
    return dispatch => {
        fetch(`${targetURL}/participant`,
            {
                method: 'POST',
                body: JSON.stringify(newParticipant),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(response =>
                dispatch({
                    type: NEW_PARTICIPANT,
                    payload: response
                }))
            .catch(err => dispatch({
                type: ADD_FAILED,
                payload: err
            }))
    }
}